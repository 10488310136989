
export const choicesPlan = [
  { id: 'Plus', name: 'Plus' },
  { id: 'Total', name: 'Total'},
  { id: 'Elite', name: 'Elite'},
  { id: 'Flex', name: 'Flex'},
];

export const verticalChoices = [
  { id: 'alianzas_empresas', name: 'Alianzas Empresas'},
  { id: 'alianzas_comercios', name: 'Alianzas Comercios'},
  { id: 'alianzas_clubes', name: 'Alianzas Clubes'},
  { id: 'alianzas_asociaciones', name: 'Alianzas Asociaciones'},
  { id: 'Corporativo', name: 'Corporativo'},
  { id: 'Workclub', name: 'Workclub'},
  { id: 'ecommerce', name: 'Ecommerce'},
  { id: 'ecommerce_local', name: 'Ecommerce Local'},
  { id: 'pase_libre', name: 'Pase Libre'},
  { id: 'country', name: 'Country'},
  { id: 'tarjeta_del_deporte', name: 'Tarjeta del Deporte'},
  { id: 'Sedes', name: 'Sedes'},
  { id: 'clubes', name: 'Clubes'},
  { id: 'otros', name: 'Otros'},
];

export const choicesProvincias = [
  {
    id: 'Ciudad Autónoma de Buenos Aires',
    name: 'Ciudad Autónoma de Buenos Aires',
  },
  {
    id: 'Buenos Aires',
    name: 'Buenos Aires',
  },
  {
    id: 'Catamarca',
    name: 'Catamarca',
  },
  {
    id: 'Chaco',
    name: 'Chaco',
  },
  {
    id: 'Chubut',
    name: 'Chubut',
  },
  {
    id: 'Córdoba',
    name: 'Córdoba',
  },
  {
    id: 'Corrientes',
    name: 'Corrientes',
  },
  {
    id: 'Entre Ríos',
    name: 'Entre Ríos',
  },
  {
    id: 'Formosa',
    name: 'Formosa',
  },
  {
    id: 'Jujuy',
    name: 'Jujuy',
  },
  {
    id: 'La Pampa',
    name: 'La Pampa',
  },
  {
    id: 'La Rioja',
    name: 'La Rioja',
  },
  {
    id: 'Mendoza',
    name: 'Mendoza',
  },
  {
    id: 'Misiones',
    name: 'Misiones',
  },
  {
    id: 'Neuquén',
    name: 'Neuquén',
  },
  {
    id: 'Río Negro',
    name: 'Río Negro',
  },
  {
    id: 'Salta',
    name: 'Salta',
  },
  {
    id: 'San Juan',
    name: 'San Juan',
  },
  {
    id: 'San Luis',
    name: 'San Luis',
  },
  {
    id: 'Santa Cruz',
    name: 'Santa Cruz',
  },
  {
    id: 'Santa Fe',
    name: 'Santa Fe',
  },
  {
    id: 'Santiago del Estero',
    name: 'Santiago del Estero',
  },
  {
    id: 'Tierra del Fuego',
    name: 'Tierra del Fuego',
  },
  {
    id: 'Tucumán',
    name: 'Tucumán',
  },
  
];

export const choicesProvinciasExpand = [
 
  ...choicesProvincias,
];

// kevin:
export const documentTypes = [
  { id: 'dni', name: 'dni'},
  { id: 'pasaporte', name: 'pasaporte'},
];