import React, { useEffect, useState } from 'react';
import { AutocompleteArrayInput, BooleanInput, choices, maxLength, ReferenceArrayField, ReferenceArrayInput, regex, required, useDataProvider, useRecordContext } from 'react-admin';
import { createNotification, getListProvider, removeAccents } from '../../helpers/helpFunctions';
import SectionForm from '../../../Components/Form/SectionForm';
import { AutoCompleteSmall } from '../../../Components/CustomFields/InputSmall';
import useCheckField from '../../../Hooks/useCheckField';
import axios from "axios";
import { apiUrl } from "../../../DataProvider";


const informacionEdit = () => {
  const record = useRecordContext();
  const [slugValue, setSlugValue] = useState(record?.slug ? record.slug : '');
  const [name, setName] = useState('');
  const [error, setError] = useState(false);
  const [choicesSedeType, setChoicesSedeType] = useState([]);

  const handlerName = (e) => {
    const value = e.target.value;
    !record?.slug && setSlugValue(removeAccents(value.toLowerCase().replace(/ /g, '-')));
    setName(value);
    if(value.length > 49 || !value.match(/^[A-Za-z0-9À-ÿ\u00f1\u00d1 ]+$/) || value.length == 0){
      setError(true);
    }else{
      setError(false);
    }
  };

  useEffect(() => {
    const getSedeTypes = async () => {
      const token = localStorage.getItem("auth");
      const tokenSend = await JSON.parse(token)?.token;
      const url = `${apiUrl}/sedes_type`;

      axios({
        url: url,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Access-Tokens": tokenSend,
          'access-control-allow-origin': '*',
        },
      })
        .then((response) => {
          setChoicesSedeType(response.data.data);
        })
        .catch((err) => {
          createNotification("error", "No se pudieron obtener los tipificados");
        });
    };
    getSedeTypes();
  }, []);

  const refetchName = useCheckField('distinct/sede/name', 'name', name); 
  const refetchSlug = useCheckField('planes', 'slug', slugValue);
  const data = [
    {
      source: 'name',
      label: 'Nombre',
      checkOnblur: refetchName,
      validate: {
        required: 'Falta el campo Nombre en Información',
        maxLength: 49,
        pattern: {
          value: /^[A-Za-z0-9À-ÿ\u00f1\u00d1 ]+$/,
          message: 'No se puede contener caracteres especiales'
        }
      },
      onChange: handlerName,
      initialValue: name,
      xs: 12
    },
    {
      type: "select",
      label: "Tipo de Sede",
      source: "sede_tipo",
      choices: choicesSedeType,
      optionLabel: "name",
      validate: { required: "Falta el campo Tipo de Sede" },
      xs: 12,
    },
    {
      source: 'slug',
      label: 'Slug',
      disabled: true,
      initialValue: slugValue,
      value: slugValue,
      tooltip: 'Parte final del url',
      xs: 12
    },
  ];

  return data;
  
};

export default informacionEdit;