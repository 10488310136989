import React, { useEffect, useState } from 'react';
import { Box, Chip, Link, Stack, Typography } from '@mui/material';
import CardBasic from '../../../Components/UI/Show/CardBasic';
import { FiExternalLink } from 'react-icons/fi';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import DotDivider from '../../../Components/UI/DotDivider';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import { useDataProvider, useRecordContext } from 'react-admin';
import { getPermissions } from '../../helpers/helpFunctions';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import { theme } from '../../../Theme/theme';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CallIcon from '@mui/icons-material/Call';
import { fontTextInfoKey, fontTitleHeader, iconsChips, styleChip, styleSpanPlan } from '../../../Theme/useStyle';
import EmailIcon from '@mui/icons-material/Email';
import HeaderCardShow from '../../../Components/UI/Show/HeaderCardShow';
import informacionEdit from '../SectionsEdit/InformacionEdit';
import { BsCardText } from 'react-icons/bs';
import TableMinimal from '../../../Components/UI/Show/TableMinimal';
import contactoEdit from '../SectionsEdit/ContactoEdit';

const fontLevelAccess = {
  fontSize: theme.typography.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.3',
  color: theme.palette.primary.main
};


const SedesHeader = () => {
  // const [merchants, setMerchants] = useState([]);
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const permis = getPermissions();
  const awaitComponent = informacionEdit();

  
  const data = [
    // {key: 'Negocio', value: merchants.map(item => item.name) },
    record?.categoria && {key: 'Categoria', value: new String(record?.categoria)},
    record?.sede_tipo && {key:'Tipo de Sede', value: record?.['sede_tipo'] ?? ''},
    // {key: 'Negocio principal', value: new String(record?.sportaccess_id)},
  ];

  const chips = [
    record?.telefono && {key: 'tel', label: record?.telefono, icon: <CallIcon /> },
    record?.['contact-email'] && {key: 'email', label: record?.['contact-email'], icon: <EmailIcon />},
    record?.whatsapp && {key: 'wsp', label: record?.whatsapp, icon: <WhatsAppIcon />},

  ];
  
  const editComponent = {
    component: awaitComponent.concat(contactoEdit()),
    label: 'Información',
    icon: <BsCardText/>
  };
  
  return(
    <CardBasic route={'sedes'} record={record} editComponent={permis !== 'operador' && editComponent}>
      <HeaderCardShow title={record.name} chips={chips} subtitle={record.slug} archived={record.archivado}/>
      <TableMinimal data={data} />
    </CardBasic>
  );
};

export default SedesHeader;